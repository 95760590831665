@tailwind base;
@tailwind components;
@tailwind utilities;

/* Inter font */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Knewave&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Luckiest+Guy&family=Rubik:wght@400;700&display=swap');
@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
  background-image: url('./images/background.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
  color: #334155;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  color: #334155;
  font-weight: bold;
}

h3 {
  font-size: 1rem;
  color: #334155;
  font-weight: bold;
}

h4 {
  font-size: 0.8rem;
  color: #334155;
  font-weight: bold;
  line-height: 1;
}

h5 {
  font-size: 0.7rem;
  color: #334155;
  font-weight: bold;
  line-height: 1;
}

h6 {
  font-size: 0.6rem;
  color: #334155;
  font-weight: bold;
  line-height: 1;
}

p {
  color: #334155;
  margin: 10px 0px;
}

span {
  color: #334155;
}

ul {
  list-style: disc;
  margin-left: 16px;
}

.main-container {
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  min-height: 100vh;
  width: 100%;
}

@media (min-width: 1024px) {
  .main-container {
    padding: 24px 100px;
  }
}

@media (min-width: 1280px) {
  .main-container {
    padding: 24px 200px;
  }
}

.box {
  background-color: white;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  margin: 0px 12px;
}

.box-left {
  background-color: white;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  margin: 0px 12px;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .box, .box-left {
    min-width: 500px;
    max-width: 600px;
  }
}

@media (min-width: 1280px) {
  .box, .box-left {
    min-width: 1000px;
    max-width: 1200px;
  }
}



input {
  border: 2px solid #e2e8f0;
  width: 100%;
  border-radius: 4px;
  padding: 12px;
}

input:focus {
  outline: none;
  border-color: #14b8a6;
}

textarea {
  border: 2px solid #e2e8f0;
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  border-radius: 4px;
  padding: 12px;
}

textarea:focus {
  outline: none;
  border-color: #14b8a6;
}

.chatbox textarea {
  border: 2px solid #e2e8f0;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 4px;
  padding: 12px;
}

select {
  border: 2px solid #e2e8f0;
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  color: #334155;
}

select:focus {
  outline: none;
  border: 2px solid #14b8a6;
}

label {
  align-self: flex-start;
  color: #64748b;
  margin-top: 24px;
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #14b8a6 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #14b8a6);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

.mini-custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #14b8a6 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #14b8a6);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

.adv-box {
  width: 400px;
}

@media (min-width: 768px) {
  .adv-block {
    width: 600px;
  }
}

@media (min-width: 1024px) {
  .adv-block {
    width: 900px;
  }
}

@media (min-width: 421px) and (max-width: 500px) {
  .card-2xs-width {
    width: 200px;
  }

  .card-2xs-height {
    height: 200px;
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .card-2xs-width {
    width: 180px;
  }

  .card-2xs-height {
    height: 180px;
  }
}

@media (max-width: 380px) {
  .card-2xs-width {
    width: 160px;
  }

  .card-2xs-height {
    height: 160px;
  }
}